/*
 * @Description: 商品管理api
 * @Autor: WangYuan
 * @Date: 2021-09-22 15:51:56
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-22 16:55:04
 */

import huaxuanRequest from '@/utils/huaxuanRequest'

/**
 * 查询商品列表
 */
export const getGoodsList = async (data) => huaxuanRequest({ url: "huaxuan_preferred/hx/product/getProductList", method: "POST", data })

/**
 * 根据id查询商品详情
 */
export const getGoodsById = async (data) => huaxuanRequest({ url: "goods/getById", method: "POST", data })

/**
 * 根据id列表查询商品列表
 */
 export const getGoodsByIds = async (data) => huaxuanRequest({ url: "huaxuan_preferred/hx/product/getProductList", method: "POST", data })

/**
 * 新增商品
 */
export const addGoods = async (data) => huaxuanRequest({ url: "goods/add", method: "POST", data })

/**
* 编辑商品
*/
export const editGoods = async (data) => huaxuanRequest({ url: "goods/edit", method: "POST", data })

/**
* 复制商品
*/
export const copyGoods = async (data) => huaxuanRequest({ url: "goods/copy", method: "POST", data })
/* 
上传
*/
export const uploadImg = async (data) => huaxuanRequest({ url: "huaxuan_preferred/hx/common/upload", method: "POST", data })

/* 
首页分类 
*/
export const getTopRegionList = async (data) => huaxuanRequest({ url: "huaxuan_preferred/hx/top_region/getTopRegionList", method: "POST", data })

/* 
首页分类 商品裂变
*/
export const getTopRegionProductList = async (data) => huaxuanRequest({ url: "huaxuan_preferred/hx/top_region/getTopRegionProductList", method: "POST", data })

/* 
店主分类
*/
export const getShopkeeperCategoryList = async (data) => huaxuanRequest({ url: "huaxuan_preferred/hx/shopkeeper_product/getShopkeeperCategoryList", method: "POST", data })

/* 
店主商品
*/
export const getShopkeeperProductList = async (data) => huaxuanRequest({ url: "huaxuan_preferred/hx/shopkeeper_product/getShopkeeperProductList", method: "POST", data })

/* 
店铺
*/
export const getShopList = async (data) => huaxuanRequest({ url: "huaxuan_preferred/hx/shopkeeper/getStoreList", method: "POST", data })
