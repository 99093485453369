<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-06-24 10:29:52
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-10-08 16:05:41
-->
<template>
	<el-dialog title="选择商品" :visible.sync="visible" :close-on-click-modal="false" :append-to-body="true" width="65%">
		<el-tabs v-model="activeName" type="card" @tab-click="handleClick" stretch="true">
			<el-tab-pane :key="item.id" v-for="item in editableTabs" :label="item.title" :name="item.id">{{ item.content }}</el-tab-pane>
		</el-tabs>

		<el-table ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%" max-height="300" @selection-change="handleSelectionChange">
			<!-- <el-table-column type="selection" align="center" header-align="center"></el-table-column> -->
			<el-table-column prop="title" label="商品名" align="center" header-align="center"></el-table-column>
			<el-table-column label="商品封面" align="center" header-align="center">
				<template slot-scope="scope">
					<img class="w50 h50" :src="$BASE_URL + scope.row.img_url" />
				</template>
			</el-table-column>
			<el-table-column prop="price" label="价格（元）" align="center" header-align="center"></el-table-column>
			<el-table-column prop="original_price" label="原价（元）" align="center" header-align="center"></el-table-column>
		</el-table>
		<span slot="footer" class="dialog-footer">
			<el-button @click="visible = false">取 消</el-button>
			<el-button type="primary" @click="submit">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { getTopRegionProductList, getTopRegionList } from '@/api/goods';

export default {
	name: 'GoodsConfigChoose',
	props: {
		show: {
			type: Boolean,
			default: false
		},
		value: {
			type: Array,
			default: () => []
		}
	},

	created() {
		this.getCat();
		// this.getList();
	},

	data() {
		return {
			baseurl: 'https://huaxuan.zihaohx.com/hx_shop',
			visible: false,
			selectList: [],
			list: [],
			activeName: '',
			editableTabs: []
		};
	},

	computed: {
		...mapGetters(['project'])
	},

	watch: {
		show: {
			immediate: true,
			handler(newValue, oldValue) {
				this.visible = this.show;
			}
		},
		visible: {
			immediate: true,
			handler(newValue, oldValue) {
				this.$emit('update:show', newValue);
			}
		}
	},

	methods: {
		handleClick(tab, event) {
			console.log(tab);
			this.list = [];
			this.getList(tab);
		},

		//获取分类
		async getCat() {
			let res = await getTopRegionList();
			console.log('获取首页分类', res);
			if (res.code == 200) {
				this.editableTabs = res.data;
				let tab = {
					name: res.data[0].id
				}
				this.getList(tab)
			
			}
		},
		// 获取商城商品列表
		async getList(tab) {
			let res = await getTopRegionProductList({
				page_num: 1,
				page_size: 20,
				region_id: tab.name
				// title: this.title,
				// projectId: this.project.id
			});
			// console.log('获取商品', res);
			// let { status, list } = await getGoodsList({ projectId: this.project.id });

			if (res.success) {
				// 筛选字段
				// this.list = res.data.list;
				this.list = [...this.list, ...res.data.list];
				this.totalPage = res.data.totalPage;
				this.selectList = this.list
				// if (this.totalPage > 1) {
				// 	this.disabled = false
				// }
				// console.log("所有page", this.totalPage);

				
			}

			// if (status == "10000") {
			//   // 筛选字段
			//   this.list = list;

			  // 根据选中列表id，筛选匹配对应数组，用于勾选回显
			  // this.selectList = this.list.filter((item) => {
			  //   return this.value.includes(item);
			  // });

			  // // 勾选回显回调方法
			  // setTimeout(() => {
			  //   this.toggleSelection(this.selectList);
			  // }, 0);
			// }
		},

		// 勾选回显
		toggleSelection(rows) {
			if (rows) {
				rows.forEach(row => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			}
		},

		// 勾选选中
		handleSelectionChange(val) {
			console.log('选中的分类', val);
			this.selectList = val;
		},

		// 提交
		submit() {
			this.$emit('submit', this.selectList.map((item) => item));
			this.visible = false;
		}
	}
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
	.el-dialog__title {
		font-size: 14px !important;
		font-weight: 600;
	}
	.el-dialog__body {
		padding: 10px 20px 30px 20px !important;
	}
}

.wrap {
	background: #f2f2f6;
	height: 400px;
	border-radius: 5px;
}
</style>
