/*
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2022-02-11 10:08:57
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-04-08 11:00:02
 */
export default {
  baseApi: 'https://diyapp.zihaohx.com',  // 后端接口地址
  baseUrl: process.env.NODE_ENV === 'production' ? '/hx_api/hx_shop' : '/hx_api/hx_shop',
  viewUrl: 'https://diyh5.zihaohx.com/#/'          // iframe嵌套的H5地址,本地开发可改为本地启动的H5地址
  // viewUrl: 'http://localhost:8081/#/'
  
}
