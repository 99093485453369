/*
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-03-24 16:07:46
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-07-02 15:40:52
 */
import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import global from '@/config/global'

// create an axios instance
const huaxuanRequest = axios.create({
  baseURL: global.baseUrl, // url = base url + request url
  headers: {
	'Content-Type':'application/x-www-form-urlencoded' || 'multipart/form-data'
  },
  withCredentials: true, // 是否跨站
  timeout: 15000, // request timeout
})


// request interceptor
huaxuanRequest.interceptors.request.use(
  config => {

      config.headers['JE-TOKEN'] = 'JE-TOKEN'


    return config
  })

// response interceptor
huaxuanRequest.interceptors.response.use(
  response => {
    const token = response.headers['right-token']
    token && store.commit('setToken', token)

    return response.data
  }
)

export default huaxuanRequest
