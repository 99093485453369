<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-08-12 17:23:08
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-08-31 20:12:08
-->
<template>
	<el-dialog title="请选择跳转页面" :visible.sync="show" width="50%">
		<div class="flex col-top selectinfo p20">
			<div class="tabs flex flex-column">
				<div class="tab flex-center mb10" :class="[value.type == tab.type ? 'tab-active' : '']"
					v-for="(tab, i) in tabs" :key="i" @click="changeTab(tab.type)">{{ tab.label }}</div>
			</div>
			<div class="ml50">
				<!-- 微页面 -->
				<template v-if="value.type == 'fixed'">
					<el-select v-model="value.id" placeholder="请选择要跳转的微页面" size="mini" style="width:250px">
						<el-option v-for="(page, i) in fixedPages" :key="i" :label="page.name" :value="page.id">
						</el-option>
					</el-select>
				</template>

				<!-- 自定义页面 -->
				<template v-if="value.type == 'custom'">
					<el-select v-model="value.id" placeholder="请选择要跳转的自定义分类" size="mini" style="width:250px">
						<el-option v-for="(page, i) in regionList" :key="i" :label="page.title" :value="page">
						</el-option>
					</el-select>
				</template>

				<!-- 店主分类 -->
				<template v-if="value.type == 'shopkeeper'">
					<el-select v-model="value.id" placeholder="请选择店主商品分类" size="mini" style="width:250px">
						<el-option v-for="(page, i) in shopCategory" :key="i" :label="page.name" :value="page">
						</el-option>
					</el-select>
				</template>

				<!-- 店主商品 -->
				<template v-if="value.type == 'shopgoods'">
					<el-select v-model="value.id" filterable remote reserve-keyword placeholder="请输入店主商品关键词" size="mini"
						style="width: 250px" :remote-method="getKeyword" :loading="loading">
						<el-option v-for="(item, i) in list" :key="i" :label="item.title"
							:value="item.shopkeeper_product_id"></el-option>
					</el-select>
				</template>

				<!-- 店铺 -->
				<template v-if="value.type == 'shop'">
					<el-select v-model="value.id" filterable remote reserve-keyword placeholder="请输入店铺名称" size="mini"
						style="width: 250px" :remote-method="getKeyword" :loading="loading">
						<el-option v-for="(item, i) in list" :key="i" :label="item.store_name"
							:value="item.store_name"></el-option>
					</el-select>
				</template>

				<!-- 普通商品搜索 -->
				<template v-if="value.type == 'link'">
					<el-select v-model="value.id" filterable remote reserve-keyword placeholder="请输入商品关键词" size="mini"
						style="width: 250px" :remote-method="getKeyword" :loading="loading">
						<el-option v-for="(item, i) in list" :key="i" :label="item.title" :value="item.product_id">
						</el-option>
					</el-select>
				</template>
			</div>
		</div>
	</el-dialog>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { getGoodsList, getTopRegionList, getShopkeeperCategoryList, getShopkeeperProductList, getShopList } from '@/api/goods';
export default {
	name: 'JumpDialog',
	inject: ['value'],
	data() {
		return {
			show: false,
			loading: false,
			list: [],
			keyword: '',
			regionList: [],
			shopCategory: [],
			type: '',
			tabs: [
				{
					label: '微页面',
					type: 'fixed'
				},
				{
					label: '自定义分类',
					type: 'custom'
				},
				{
					label: '店主分类',
					type: 'shopkeeper'
				},
				{
					label: '店主商品',
					type: 'shopgoods'
				},
				{
					label: '商品链接',
					type: 'link'
				},
				{
					label: '店铺展示',
					type: 'shop'
				},
				{
					label: '不跳转',
					type: undefined
				}
			]
		};
	},
	created() {
		// this.getRegion();
		// this.getShopkeeperCategory()
	},
	computed: {
		...mapGetters(['project', 'fixedPages']),
		customPages() {
			return this.project.pages;
		}
	},
	methods: {
		// 推荐分类
		async getRegion() {
			let res = await getTopRegionList();
			console.log('获取分类', res);
			this.regionList = res.data;
		},
		open() {
			this.show = true;
		},
		// 店主分类
		async getShopkeeperCategory() {
			let res = await getShopkeeperCategoryList({});
			this.shopCategory = res.data;
			console.log("店主分类", res)
		},


		// changeTab(type) {
		//   this.$set(this.value, "type", type);
		//   this.$set(this.value, "id", "");
		// },
		// 店铺

		changeTab(type) {
			console.log("切换tab", type)
			this.type = type;
			this.list = [];
			switch (type) {
				case "custom":
					this.getRegion();
					break;
				case "link":
					this.keyword = '';
					break;
				case "shopkeeper":
					this.getShopkeeperCategory();
					break;
				case "shopgoods":
					this.keyword = '';
					break;
				case "shop":
					this.keyword = ''
					break;
			}


			this.$set(this.value, 'type', type);
			this.$set(this.value, 'id', '');
		},
		setPageId(id) {
			this.$set(this.value, 'id', id);
		},
		getKeyword(keyword) {
			this.list = [];
			this.keyword = keyword;
			if (keyword !== '') {
				console.log('输入', keyword);
				this.loading = true;
				this.searchGoods();
			}
		},

		async searchGoods() {
			let res = []
			switch (this.type) {
				case 'link':
					res = await getGoodsList({
						page_num: 1,
						page_size: 10,
						title: this.keyword

					});
					break;
				case 'shopgoods':
					res = await getShopkeeperProductList({
						page_num: 1,
						page_size: 10,
						title: this.keyword

					});
					break;
				case 'shop':
					res = await getShopList({
						page_num: 1,
						page_size: 10,
						store_name: this.keyword

					});
					break;

			}
			console.log('搜索结果', res);
			this.loading = false;
			this.list = [...this.list, ...res.data.list];

		}
	}
};
</script>

<style lang="scss" scoped>
.selectinfo {
	border: 1px solid #979797;
	border-radius: 5px;
}

.tabs {
	display: flex;

	.tab {
		width: 100px;
		height: 30px;
		color: #979797;
		border: solid 1px #ebeff0;
		cursor: pointer;
	}

	.tab-active {
		color: $color-theme;
		border: solid 1px $color-theme;
		background: #e0edff;
	}
}
</style>
